import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ConferenceParticipant } from "@regal-voice/shared-types";

export type NonQueueParticipant =
    | ConferenceParticipant<"contact">
    | ConferenceParticipant<"external">
    | ConferenceParticipant<"internal">
    | ConferenceParticipant<"external-phonebook">;

export type ConferenceSnapshot = {
    conferenceFriendlyId: string;
    /**
     * Kept for backwards compatibility on all actions. Sometimes conference will start without it populated. Later it should always have a value.
     */
    conferenceSid?: string;
    startedAt: number;
    recording: boolean;
    /**
     * This will hold the last updated timestamp for any participant (sequence #).
     */
    lastUpdatedAt: number;
    participants: ConferenceParticipant[];
    hungup?: boolean;
};

export const __initialConferenceSnapshotState: ConferenceSnapshot = {
    conferenceFriendlyId: "",
    startedAt: 0,
    recording: false,
    lastUpdatedAt: 0,
    participants: [],
};

const conferenceSnapshotSlice = createSlice({
    name: "conferenceSnapshot",
    initialState: __initialConferenceSnapshotState,
    reducers: {
        /**
         * Updates state with the new snapshot only if we have a new lastUpdatedAt timestamp.
         */
        updateConferenceSnapshot(state, action: PayloadAction<ConferenceSnapshot>) {
            if (!action.payload.lastUpdatedAt) {
                throw new Error("Tried to update conference with no lastUpdatedAt");
            }
            // twilio has a race condition so we can trust things that
            // when 2 things "happen at the same time" the second event we get is valid
            if (action.payload.lastUpdatedAt >= state.lastUpdatedAt) {
                return { ...action.payload };
            }
        },

        /**
         * Resets the conference snapshot entirely.
         */
        clearConferenceSnapshot() {
            return __initialConferenceSnapshotState;
        },

        /**
         * Used to optimistically mute/unmute the agent in the conference.
         * Will eventually be replaced by the actual conference snapshot.
         */
        toggleAgentMute(state, action: PayloadAction<{ callerUri: string }>) {
            const agent = state.participants.find(
                (p) => p.type === "internal" && p.callerUri === action.payload.callerUri
            );
            if (!agent) {
                throw new Error("Could not find current agent in conference state snapshot");
            }
            agent.muted = !agent.muted;
        },
        /**
         * Used to optimistically hold/unhold a participant in the conference.
         * Will eventually be replaced by the actual conference snapshot.
         */
        toggleParticipantHold(state, action: PayloadAction<{ callSid: string }>) {
            const participant = state.participants.find((p) => "callSid" in p && p.callSid === action.payload.callSid);
            if (participant) {
                participant.hold = !participant.hold;
            }
        },

        /**
         * Used to optimistically toggle the recording state of the conference.
         * Will eventually be replaced by the actual conference snapshot.
         */
        toggleSnapshotRecording(state) {
            state.recording = !state.recording;
        },

        /**
         * Used to optimistically drop a participant from the conference.
         * If you're dropping a queue, you should provide the queueId, otherwise the callSid.
         * Will eventually be replaced by the actual conference snapshot.
         */
        dropParticipant(state, action: PayloadAction<{ callSid?: string; queueId?: string }>) {
            if (!action.payload.callSid && !action.payload.queueId) {
                throw new Error("Tried to drop participant without a callSid or queueId");
            }
            if (action.payload.callSid) {
                const participantIndex = state.participants.findIndex((p) => {
                    const toRemove = "callSid" in p && p.callSid === action.payload.callSid;
                    return toRemove;
                });
                if (participantIndex !== -1) {
                    state.participants.splice(participantIndex, 1);
                }
            } else if (action.payload.queueId) {
                const participantIndex = state.participants.findIndex(
                    (p) => p.type === "queue" && p.queueId === action.payload.queueId
                );
                if (participantIndex !== -1) {
                    state.participants.splice(participantIndex, 1);
                }
            }
        },
    },
});

export const {
    updateConferenceSnapshot,
    clearConferenceSnapshot,
    toggleAgentMute,
    toggleParticipantHold,
    toggleSnapshotRecording,
    dropParticipant,
} = conferenceSnapshotSlice.actions;
export default conferenceSnapshotSlice.reducer;
