import { createSelector } from "@reduxjs/toolkit";

import { RootReducerShape } from "Services/state/Storage";
import { selectTasks } from "Services/state/tasks/Selectors";
import { isPendingInboundConferenceTask } from "Services/Task.service";

export const selectConferenceState = (state: RootReducerShape) => {
    return state.conference;
};

/**
 * These selectors are still needed because we still have a reliance on the Twilio device.
 *
 * @remarks
 * Once we clean up the device dependencies, we could potentially rename or refactor these selectors.
 */
export const selectConference = createSelector(selectConferenceState, (conferenceState) => {
    return conferenceState.activeConference.value;
});

export const selectDeviceCallConnectionParams = createSelector(
    selectConference,
    (conference) => conference.connectionParams
);
export const selectActiveConferenceTask = createSelector(selectConference, selectTasks, (conference, tasks) => {
    // TODO, where should we put this...
    // this is really the only value we need from the conference
    const conferenceTaskId = conference.connectionParams?.taskSid;
    if (conferenceTaskId) {
        const active = Object.values(tasks).find((task) => {
            return task.taskSid == conferenceTaskId;
        });
        return active;
    }
    return undefined;
});

//////////////////////////////////////////

/**
 * These selectors that we have snapshot versions of and can be removed
 *
 * @remarks
 * remove when we clean up the rolloutStateSse flags
 * */
export const selectIsPowerDialTaskWaiting = createSelector(
    selectActiveConferenceTask,
    (activeConferenceTask) =>
        activeConferenceTask &&
        activeConferenceTask.status === "pending" &&
        activeConferenceTask.attributes.autoAnswer &&
        activeConferenceTask.taskChannelUniqueName !== "regal" // "regal" is used for sms
);

const selectInboundCallTaskWaiting = (state: RootReducerShape): boolean => {
    const activeConferenceTask = selectActiveConferenceTask(state);
    return !!(
        activeConferenceTask &&
        activeConferenceTask.status != "wrapping" &&
        isPendingInboundConferenceTask(activeConferenceTask)
    );
};

export const selectDeviceCallSid = createSelector(selectConference, (conference) => conference.deviceCallSid);

export const selectShowPanel = createSelector(
    selectDeviceCallSid,
    selectActiveConferenceTask,
    selectIsPowerDialTaskWaiting,
    selectInboundCallTaskWaiting,
    (deviceCallSid, activeConferenceTask, inboundCallTaskWaiting, isPowerDialTaskWaiting) => {
        const show = !!(
            deviceCallSid ||
            isPowerDialTaskWaiting ||
            inboundCallTaskWaiting ||
            activeConferenceTask?.status == "accepted"
        );

        return show;
    }
);

export const selectDeviveCallConnected = createSelector(selectConference, (conference) => conference.connected);
export const selectDeviceCallParticipants = createSelector(selectConference, (conference) => conference.participants);
export const selectBargingParticipants = createSelector(selectConference, ({ participants }) => {
    if (!participants) {
        return [];
    }
    return Object.values(participants).filter(({ managerAction }) => managerAction == "barge");
});
