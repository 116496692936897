import { Dayjs } from "dayjs";
import { Edge, Node, XYPosition } from "reactflow";

export type { Edge, Node, XYPosition };
export type Elements<T = any> = (Node<T> | Edge<T>)[];
export type FlowElement<T = any> = Node<T> | Edge<T>;

export enum JourneyStatus {
    LIVE = "live",
    DRAFT = "draft",
}

export interface JourneysStickyNoteData {
    id: string;
    text?: string;
    author?: string;
    date?: any;
}

export interface JourneysStickyNote {
    nodeId: string;
    data: JourneysStickyNoteData;
    position: XYPosition;
}

export interface Tag {
    id: string;
    name: string;
}

export interface Journey {
    id: string;
    friendlyId: string;
    name: string;
    description?: string;
    state: JourneyStatus;
    flow: Elements;
    createdAt: string;
    createdBy: string;
    updatedAt: string;
    updatedBy: string;
    finishedJourneys?: number;
    inProgressJourneys?: number;
    version: number;
    originalCreatedAt: string;
    hidden?: boolean;
    nodeAnalytics?: Record<string, NodeMetricsData>;
    metadata?: any;
    stickyNotes?: Array<JourneysStickyNote>;
    tags?: Tag[];
}

export interface JourneyWithMetrics extends Journey {
    journeyStats?: JourneyUsersStats;
}

export interface JourneyUsersStats {
    inProgress: number;
    finished: number;
    stopped: number;
    failed: number;
    loading?: boolean;
}

export interface JourneyMetaData {
    createdAt: string;
    updatedAt: string;
    version: number;
    originalCreatedAt: string;
    id: string;
    friendlyId: string;
    name: string;
    state: JourneyStatus;
}

export interface DynamicHandle {
    id?: string | number;
    displayValue?: string | number;
    connectorShouldDisplay: boolean;
    name?: string;
}

export interface Variant {
    id: number;
    isActive: boolean;
    name?: string;
    weight?: number;
    previousId?: number;
}

// atm Decision AI node is using a slightly
// different implementation of branching
// so for the moment is best to keep a separate
// type for its variants as well
export interface DecisionAiVariant {
    id: number;
    name: string;
    description: string;
}

// TODO move to shared-types
export enum NodeType {
    Trigger = "trigger",
    Delay = "delay",
    Call = "call",
    SMS = "sms",
    AgentSMSTask = "agentSms",
    CancelTask = "cancelTask",
    CustomTask = "customTask", // todo: rename after CustomSMSTask is renamd
    Conditional = "conditional",
    ABTest = "ABTest",
    Webhook = "webhook",
    AssignTargetAgent = "assignTargetAgent",
    UpdateTask = "updateTask",
    Reminder = "reminder",
    ScheduledCallback = "scheduledCallback",
    StickyNote = "stickyNote",
    UpdateContact = "updateContact",
    DEMO_EightByEight = "eightByEight",
    DEMO_RinglessVM = "ringlessVM",
    DEMO_AgentlessCall = "agentlessCall",
    DEMO_VoiceAI = "voiceAI",
    DEMO_Genesys = "genesys",
    DEMO_Talkdesk = "talkdesk",
    DEMO_NICE = "nice",
    DEMO_Five9 = "five9",
    DEMO_SMSAI = "smsai",
    DEMO_CustomTaskAI = "customAITask",
    DecisionAI = "decisionAI",
    DEMO_SendEmail = "sendEmail",
    DEMO_EmailTask = "emailTask",
}

export enum NodeTriggerType {
    Custom = "custom",
    AddContactToStaticSegment = "addContactToStaticSegment",
    RegalVoice = "regalVoiceEvent",
    Pageview = "pageview",
    Scheduled = "scheduled",
}

export enum ConditionalEventType {
    ContactAttribute = "contactAttribute",
    Custom = "customEvent",
    RegalVoice = "regalVoiceEvent",
    TriggeringEvent = "triggeringEvent",
}

export const NodeTypeStrings = [
    NodeType.Trigger,
    NodeType.Delay,
    NodeType.Call,
    NodeType.SMS,
    NodeType.AgentSMSTask,
    NodeType.UpdateContact,
    NodeType.CancelTask,
    NodeType.CustomTask,
    NodeType.Conditional,
    NodeType.ABTest,
    NodeType.Webhook,
    NodeType.AssignTargetAgent,
    NodeType.UpdateTask,
    NodeType.Reminder,
    NodeType.ScheduledCallback,
    NodeType.StickyNote,
    NodeType.DEMO_EightByEight,
    NodeType.DEMO_RinglessVM,
    NodeType.DEMO_AgentlessCall,
    NodeType.DEMO_VoiceAI,
    NodeType.DEMO_Genesys,
    NodeType.DEMO_Talkdesk,
    NodeType.DEMO_NICE,
    NodeType.DEMO_Five9,
    NodeType.DEMO_SMSAI,
    NodeType.DEMO_CustomTaskAI,
    NodeType.DecisionAI,
    NodeType.DEMO_SendEmail,
    NodeType.DEMO_EmailTask,
];

export interface NodeMetricsData {
    nodeUuid: string;
    currentUserCount: number;
}

export interface NodeData {
    analyticsData: NodeMetricsData;
    empty: boolean;
    friendlyId?: string;
    nodeId?: string;
}

export interface RequestNodeMetricsData {
    nodes: Array<NodeMetricsData>;
}

export interface DateInterval {
    startDate: Dayjs | string;
    endDate: Dayjs | string;
}

export type ReactFlowNodeData = {
    id: string;
    data?: Record<string, any>;
    position?: XYPosition;
};
