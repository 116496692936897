import { useLayoutEffect, useState } from "react";

import { v4 as uuidv4 } from "uuid";

import { isIframed } from "Services/embed/EmbedUtilService";
import { useDatadog, useIntercom, useZendesk } from "Services/ThirdPartyServices/ThirdPartyServices";
import {
    PUBLIC_ENV_DD_RUM_APP_ID,
    PUBLIC_ENV_DD_RUM_CLIENT_TOKEN,
    PUBLIC_ENV_DD_RUM_ENV,
    PUBLIC_ENV_DD_RUM_TRACING_ORIGINS,
    PUBLIC_ENV_DD_RUM_VERSION,
    PUBLIC_ENV_INTERCOM_APP_ID,
    PUBLIC_ENV_ZENDESK_KEY,
} from "Utils/EnvironmentVars";

const SESSION_ID_STORAGE_KEY = "appInstanceId";
const DATADOG_SETTINGS = {
    applicationId: PUBLIC_ENV_DD_RUM_APP_ID,
    clientToken: PUBLIC_ENV_DD_RUM_CLIENT_TOKEN,
    env: PUBLIC_ENV_DD_RUM_ENV,
    version: PUBLIC_ENV_DD_RUM_VERSION,
    allowedTracingOrigins: PUBLIC_ENV_DD_RUM_TRACING_ORIGINS,
};

const INTERCOM_SETTINGS = {
    appId: PUBLIC_ENV_INTERCOM_APP_ID,
};

const ZENDISK_SETTINGS = {
    key: PUBLIC_ENV_ZENDESK_KEY,
};

export default function useThirdPartyScripts(): void {
    const [appInstanceId, setAppInstanceId] = useState(sessionStorage.getItem(SESSION_ID_STORAGE_KEY) || "");

    useLayoutEffect(() => {
        if (!appInstanceId) {
            setAppInstanceId(() => {
                const sessionId = uuidv4();
                sessionStorage.setItem(SESSION_ID_STORAGE_KEY, sessionId);
                return sessionId;
            });
        }
    }, [appInstanceId]);

    const { sessionId } = useDatadog({
        ...DATADOG_SETTINGS,
        appInstanceId,
        isIframed: !!isIframed(),
    });

    useIntercom({ ...INTERCOM_SETTINGS, sessionId });

    useZendesk(ZENDISK_SETTINGS);
}
