import { Layout, Menu } from "antd";
import { clsx } from "clsx";
import { Link, useLocation, matchPath } from "react-router-dom";

import { PageSubNavItem } from "Types/Settings";

import sidenavItems from "./SidenavItems.module.scss";

type SidenavItemsProps = {
    sidenavTitle: string;
    items: Array<PageSubNavItem>;
    basePath: string;
    hasSubPages?: boolean;
};

export function SidenavItems({ sidenavTitle, items, basePath, hasSubPages }: SidenavItemsProps): JSX.Element {
    const { pathname } = useLocation();
    let selectedItem;

    if (!hasSubPages) {
        selectedItem = items
            .flatMap(
                // if it has children, the submenu title is not a link anymore,
                // so it should not be selectable
                (item) => (item.children ? [...item.children] : [item])
            )
            .find(
                // match the side menu item by its href
                // or, if it has a list of tabs, find a matching tab
                // this allows the same menu item to be highlighted for multiple URLs
                (item) =>
                    matchPath(pathname, { path: basePath + item.href, exact: true }) ||
                    item.tabs?.find((tab) => matchPath(pathname, { path: basePath + tab, exact: true }))
            );
    } else {
        // We check if the href is included in the pathname
        selectedItem = items.find((item) => pathname.includes(basePath + item.href));
    }
    const defaultSelectedKey = (selectedItem || items[0]).key;
    const selectedKey = selectedItem ? selectedItem.key : defaultSelectedKey;

    return (
        <Layout.Sider className={sidenavItems.sidebarSettings}>
            <h2 className={sidenavItems.settingsMenuTitle}>{sidenavTitle}</h2>
            <Menu style={{ backgroundColor: "#6a6acb" }} selectedKeys={[defaultSelectedKey]} theme="dark" mode="inline">
                {items.map((item) => {
                    return (
                        (item.isVisible === undefined || item.isVisible) &&
                        (item.children && item.children.length > 0 ? (
                            <Menu.SubMenu title={item.title} className={sidenavItems.submenu}>
                                {item.children.map(
                                    (subitem) =>
                                        (subitem.isVisible === undefined || subitem.isVisible) && (
                                            <Menu.Item
                                                className={clsx(sidenavItems.submenuItem, {
                                                    [sidenavItems.selected]: subitem.key === selectedKey,
                                                })}
                                                key={subitem.key}
                                            >
                                                <Link to={`${basePath}${subitem.href}`}>{subitem.title}</Link>
                                            </Menu.Item>
                                        )
                                )}
                            </Menu.SubMenu>
                        ) : (
                            <Menu.Item
                                className={clsx(sidenavItems.menuItem, {
                                    [sidenavItems.selected]: item.key === selectedKey,
                                })}
                                key={item.key}
                            >
                                <Link to={`${basePath}${item.href}`}>{item.title}</Link>
                            </Menu.Item>
                        ))
                    );
                })}
            </Menu>
        </Layout.Sider>
    );
}
