import { useCallback, useEffect, useRef, useState } from "react";

import { LeftOutlined } from "@ant-design/icons";
import { InputRef, message, Modal } from "antd";
import { useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router";

import { RVButton } from "Components/elements/RVButton/RVButton";
import { SearchableFromNumber } from "Components/shared/SearchableFromNumber/SearchableFromNumber";
import { useDefaultFromNumber } from "Hooks/useDefaultFromNumber";
import { useEnsureAvailability } from "Hooks/useEnsureAvailability/useEnsureAvailability";
import { isPhoneNumberValid } from "Services/CommunicationService";
import { createManualOutboundEmailTask } from "Services/ConversationsApiService";
import { normalizeError, renderErrorMessage } from "Services/LoggingService";
import { selectAgentEmail, selectAgentInformation } from "Services/state/agent/AgentInformationSlice";
import { useRVDispatch } from "Services/state/Storage";
import { handleOutboundCallTaskCreationThunk } from "Services/state/tasks/Thunks";

import { ContactSearch } from "./ContactSearch/ContactSearch";
import { DialPadActions, DialPadViews } from "./SearchableDialPad.types";
import { handleOutboundSmsTaskCreation, logger } from "./utils";

import type { NullOr } from "@regal-voice/shared-types";
import type { PhoneEntry } from "Types/Campaign";
import type { Profile } from "Types/Profile";

export default function SearchableDialPad({
    onCancel,
    defaultSearchInput,
}: {
    onCancel: () => void;
    defaultSearchInput?: string;
}): JSX.Element {
    const dispatch = useRVDispatch();
    const router = useHistory();

    const searchInput = useRef<InputRef>(null);
    const [searchedNumber, setSearchedNumber] = useState<string | undefined>(undefined);
    const [contact, setContact] = useState<Partial<Profile> | undefined>(undefined);
    const { defaultFromNumber } = useDefaultFromNumber({ contact });
    const [view, setView] = useState<DialPadViews>(DialPadViews.CONTACT_SEARCH);
    const [action, setAction] = useState<DialPadActions | undefined>(undefined);
    const [agentPhone, setAgentPhone] = useState<NullOr<PhoneEntry>>(
        defaultFromNumber ? { source: defaultFromNumber } : null
    );
    const agentInformation = useSelector(selectAgentInformation);
    const [loading, setLoading] = useState(false);
    const loadingStateTimeout = useRef<NullOr<ReturnType<typeof setTimeout>>>(null);
    // non null selector, this seems suspicious
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const agentEmail = useSelector(selectAgentEmail)!;

    const isAgentDesktopPage = useRouteMatch({
        path: "/agent",
        exact: true,
    });

    useEffect(
        () => () => {
            if (loadingStateTimeout.current) {
                clearTimeout(loadingStateTimeout.current);
            }
        },
        []
    );

    useEffect(() => {
        searchInput.current?.focus();
    }, []);

    useEffect(() => {
        // defaultFromNumber might take a time to be correctly set because it needs to fetch the current agent information from the API
        setAgentPhone({ source: defaultFromNumber });
    }, [defaultFromNumber]);

    function onContactSelect(contact: Partial<Profile>, action: DialPadActions): void {
        setContact(contact);
        setAction(action);
        if (action !== DialPadActions.EMAIL) {
            setView(DialPadViews.FROM_NUMBER_PICKER);
        }
    }

    const onAgentPhoneNumberSelect = useCallback((value: string) => {
        if (value) {
            setAgentPhone({ source: value });
            return;
        }

        setAgentPhone(null);
    }, []);

    function goBack() {
        setView(DialPadViews.CONTACT_SEARCH);
    }

    const title =
        view === DialPadViews.CONTACT_SEARCH ? (
            <h4>Enter a Name, Phone Number, Email or External ID</h4>
        ) : (
            <h4>
                <LeftOutlined onClick={goBack} /> <span>Select phone number to </span>
                <span>{action === DialPadActions.CALL ? "dial" : "message"}</span> <span>from</span>
            </h4>
        );

    const agentRegalVoicePhone = agentInformation?.attributes?.agentRegalVoicePhone;

    const { ensureAgentIsAvailable } = useEnsureAvailability();

    const closeDialpad = useCallback(() => {
        setLoading(false);
        onCancel();
    }, [onCancel, setLoading]);

    const onSuccess = useCallback(() => {
        closeDialpad();
        if (!isAgentDesktopPage) {
            router.push("/agent");
        }
    }, [closeDialpad, router, isAgentDesktopPage]);

    const onSubmit = useCallback(async () => {
        const taskCreationFlowStart = performance.now();

        setLoading(true);

        const contactPhoneNumber: string | undefined | null =
            typeof searchedNumber === "string" && isPhoneNumberValid(searchedNumber)
                ? searchedNumber
                : contact?.contactPhone;

        const agentPhoneNumber = agentPhone?.source;
        if (!agentPhoneNumber && action !== DialPadActions.EMAIL) {
            renderErrorMessage({
                content: "Attempted to create manual outbound task with missing agent phone",
                extraData: { action },
            });
            closeDialpad();
            return;
        }

        ensureAgentIsAvailable();

        loadingStateTimeout.current = setTimeout(() => {
            message.error(`Task failed to be accepted.`);
            closeDialpad();
        }, 15000);

        if (action === DialPadActions.CALL) {
            if (!contactPhoneNumber) {
                renderErrorMessage({
                    content: "Attempted to create manual outbound task with missing contact phone",
                    extraData: { action },
                });
                closeDialpad();
                return;
            }
            dispatch(
                handleOutboundCallTaskCreationThunk(
                    {
                        contactPhone: contactPhoneNumber,
                        // typescript doesn't detect that agentPhoneNumber can't be undefined at this point
                        // since we're exiting early if it's undefined and the action is not "email"
                        regalVoicePhone: agentPhoneNumber as string,
                        triggeredFromDialpad: true,
                        profileId: contact?.id,
                    },
                    onSuccess,
                    closeDialpad
                )
            );
        } else if (action === DialPadActions.SMS) {
            if (!contactPhoneNumber) {
                renderErrorMessage({
                    content: "Attempted to create manual outbound task with missing contact phone",
                    extraData: { action },
                });
                closeDialpad();
                return;
            }

            await handleOutboundSmsTaskCreation(
                contactPhoneNumber,
                // typescript doesn't detect that agentPhoneNumber can't be undefined at this point
                // since we're exiting early if it's undefined and the action is not "email"
                agentPhoneNumber as string,
                taskCreationFlowStart,
                closeDialpad,
                contact?.id
            );

            onSuccess();
        } else if (action === DialPadActions.EMAIL) {
            try {
                await createManualOutboundEmailTask({
                    contactEmail: contact?.email,
                    contactPhone: contactPhoneNumber,
                    agentEmail: agentEmail,
                    profileId: contact?.id,
                });
                onSuccess();
            } catch (err: unknown) {
                const error = normalizeError(err);
                renderErrorMessage({ content: "Task failed to be created", error });
                closeDialpad();
            }
        } else {
            logger.error("Error creating outbound task. Incorrect action was set.");
            message.error("Task failed to be created.");
            closeDialpad();
        }
    }, [
        action,
        agentEmail,
        agentPhone?.source,
        closeDialpad,
        contact?.id,
        contact?.contactPhone,
        contact?.email,
        dispatch,
        ensureAgentIsAvailable,
        onSuccess,
        searchedNumber,
    ]);

    useEffect(() => {
        // when the user clicks the email icon next to a contact's name
        // we want to skip showing the phone number selector for the agent
        // and create the email task right away
        if (!loading && action === DialPadActions.EMAIL) {
            onSubmit();
        }
    }, [action, onSubmit, loading]);

    return (
        <Modal title={title} onCancel={onCancel} footer={null} open>
            {view === DialPadViews.CONTACT_SEARCH ? (
                <ContactSearch
                    onContactSelect={onContactSelect}
                    inputRef={searchInput}
                    setDialPadNumber={setSearchedNumber}
                    defaultPhone={defaultSearchInput}
                />
            ) : (
                <div className="flex-row justify-between row-spacer align-center">
                    <SearchableFromNumber
                        onChange={onAgentPhoneNumberSelect}
                        agentPhoneNumber={agentRegalVoicePhone}
                        size="middle"
                        {...(defaultFromNumber ? { initialValue: defaultFromNumber } : {})}
                    />
                    <RVButton
                        type="primary"
                        data-testid="searchable-from-number-submit"
                        size="large"
                        onClick={onSubmit}
                        disabled={!agentPhone || !contact}
                        loading={loading}
                    >
                        Go
                    </RVButton>
                </div>
            )}
        </Modal>
    );
}
