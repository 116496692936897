import { message } from "antd";

import { getSmsError } from "Services/CommunicationService";
import { sendOutboundSms } from "Services/ConversationsApiService";
import { getLogger } from "Services/LoggingService";
import { Marks, performanceMark } from "Services/PerfService";

export const logger = getLogger("Searchable Dialpad");

export async function handleOutboundSmsTaskCreation(
    contactPhone: string,
    regalVoicePhone: string,
    creationStartTime: number,
    errorCallback: () => void,
    profileId?: string
): Promise<{ success: boolean; taskSid: string } | undefined> {
    try {
        const { success, taskSid } = await sendOutboundSms({
            contactPhone,
            regalVoicePhone,
            profileId,
        });

        if (!success) {
            throw new Error();
        }

        performanceMark(Marks.getManualOutboundSmsCreate(taskSid), { startTime: creationStartTime });
        return { success, taskSid };
        // The dialpad will be closed upon reservation.created sse, see closeDialpadIfTaskCreated
        // update type to unknown and use normalizeError
    } catch (err: any) {
        const userFacingError = getSmsError(err);
        message.error(userFacingError);

        logger.error(err);

        errorCallback();
    }
}
