import { Fragment } from "react";

import { createAsyncThunk, Dispatch } from "@reduxjs/toolkit";
import { NullOr, EmailAddress, EmailTaskAttributes, INTEGRATION_AUTH_ERROR_MESSAGE } from "@regal-voice/shared-types";
import { capitalize, uniqBy } from "lodash";
import { useHistory } from "react-router";

import { apolloMutation } from "Services/ApolloClientService";
import { createManualOutboundEmailTask, sendEmail } from "Services/ConversationsApiService";
import { getLogger, renderErrorMessage } from "Services/LoggingService";
import { updateAgent } from "Services/marketing-api/agents/mutations";
import {
    // utils
    createOptimisticEmail,
    // actions
    addOptimisticEmail,
    emailSendAttempted,
    emailSendFinished,
    emailErrorReset,
    // selectors
    selectNewEmailMeta,
    emailRecipientsToChanged,
    selectTo,
    _newEmailEventAction,
    _replyToEmailAction,
    generateReplyEmailMeta,
    NewEmailMeta,
} from "Services/state/agent/desktopUI/email/EmailUISlice";
import { selectDefaultAvailableStatus } from "Services/state/brand";
import { selectPageAwareContactObject } from "Services/state/contacts/Selectors/Selectors";
import { RootState } from "Services/state/Storage";
import { selectActiveTask, selectTasksList } from "Services/state/tasks/Selectors";

import { selectWorkerSid, selectAgentIntegrationEmail } from "../../AgentInformationSlice";
import { Attachment, selectIsContactsPage } from "../AgentDesktopUISlice";
import { selectPersistedThread } from "./EmailUIPersistSelectors";
import { persistDataForThread, clearPersistedDataForThread } from "./EmailUIPersistSlice";

import type { Profile } from "Types/Profile";

type SendEmailProps = {
    agentEmail: EmailAddress;
    text: string;
    subject: string;
    postSendCallback: () => void;
    contactPhone?: string;
    profileId: string;
    contactEmail: string;
    history: ReturnType<typeof useHistory>;
    attachments: Array<Attachment>;
    persistedThreadKey?: string;
    emailIntegrationType?: string;
};

const UNAUTHORIZED_INTEGRATION_MESSAGE = (integrationName: string) =>
    `Please go to My Settings and reauthorize your ${integrationName} Integration.`;
const DEFAULT_EMAIL_SEND_ERROR_MESSAGE =
    "There was an error sending the email. Please try again. If the issue persists, please contact customer support.";

const logger = getLogger("Email Thunks");

export function sendOptimisticEmail({
    text,
    subject,
    agentEmail,
    postSendCallback,
    contactPhone,
    profileId,
    history,
    attachments,
    persistedThreadKey,
    emailIntegrationType,
}: SendEmailProps) {
    return async function thunk(dispatch: Dispatch, getState: () => RootState): Promise<void> {
        dispatch(emailSendAttempted());

        const rootState = getState();
        const newEmailMeta = selectNewEmailMeta(rootState);
        const isOnMyContactsPage = selectIsContactsPage(rootState);
        const contact = selectPageAwareContactObject(rootState);
        const pendingOrActiveTasks = selectTasksList(rootState);
        const availableStatus = selectDefaultAvailableStatus(rootState);
        const agentSid = selectWorkerSid(rootState);

        if (!contact?.email) {
            logger.error("Could not find email address for contact in Redux");
            return;
        }

        const existingActiveTaskForContact = pendingOrActiveTasks.find(
            (task) =>
                ["pending", "accepted", "wrapping"].includes(task.status) &&
                (task.attributes.email === contact.email || task.attributes.profileId === contact.id)
        );

        if (isOnMyContactsPage && !existingActiveTaskForContact) {
            if (availableStatus?.name) {
                apolloMutation({
                    mutation: updateAgent,
                    variables: {
                        updateUserData: {
                            twilioSid: agentSid,
                            status: availableStatus.name,
                        },
                    },
                }).catch((error) =>
                    logger.error(
                        "Error when attempting to turn agent available for task associated with email sent from My Contacts view",
                        {
                            error,
                        }
                    )
                );
                createManualOutboundEmailTask({
                    contactEmail: contact.email,
                    contactPhone,
                    agentEmail: agentEmail.address,
                    profileId: contact.id,
                }).catch((error) =>
                    logger.error(
                        "Error when attempting tocreate task associated with email sent from My Contacts view",
                        {
                            error,
                        }
                    )
                );
            }
        }

        try {
            const emailProps = {
                ...newEmailMeta,
                body: text,
                senderEmail: agentEmail.address,
                subject: subject,
                attachments,
                emailIntegrationType,
            };
            const sendEmailResponse = await sendEmail(emailProps);
            const rvEmailMessageId = sendEmailResponse.emailMessageIdentifiers?.rvEmailMessageId;
            const rvThreadId = sendEmailResponse.emailMessageIdentifiers?.rvThreadId;

            if (rvEmailMessageId && rvThreadId) {
                dispatch(
                    addOptimisticEmail(
                        createOptimisticEmail({
                            attachments,
                            state: newEmailMeta,
                            text,
                            subject,
                            agentEmail,
                            contactPhone,
                            profileId,
                            rvEmailMessageId,
                            rvThreadId,
                        })
                    )
                );
            }

            dispatch(emailSendFinished());
            persistedThreadKey && dispatch(clearPersistedDataForThread(persistedThreadKey));
            postSendCallback();
            // update type to unknown and use normalizeError
        } catch (err: any) {
            dispatch(emailErrorReset());
            switch (err.statusCode) {
                case 422:
                    if (err.error == INTEGRATION_AUTH_ERROR_MESSAGE) {
                        const integrationName = capitalize(emailIntegrationType || "email");
                        renderErrorMessage({
                            content: UNAUTHORIZED_INTEGRATION_MESSAGE(integrationName),
                            customNode: (
                                <Fragment>
                                    Connect your {integrationName} account in the{" "}
                                    <span
                                        style={{ textDecoration: "underline", cursor: "pointer" }}
                                        onClick={() => history.push("/settings/my-settings/agent-integrations")}
                                    >
                                        My Settings
                                    </span>{" "}
                                    page to send an email
                                </Fragment>
                            ),
                            destroyOnClick: true,
                        });
                        return;
                    }
                    return;
                case 400:
                    if (Array.isArray(err.message)) {
                        err.message.forEach((inputError: string) => {
                            renderErrorMessage({ content: inputError });
                        });
                    } else {
                        renderErrorMessage({ content: err.message });
                    }
                    return;
                default:
                    renderErrorMessage({ content: DEFAULT_EMAIL_SEND_ERROR_MESSAGE });
            }
        }
    };
}

export const contactEmailChanged = createAsyncThunk<
    void,
    { contactEmail: string; previousContactEmail: string },
    { state: RootState }
>("emailUI/contactEmailChanged", ({ contactEmail, previousContactEmail }, { dispatch, getState }) => {
    if (contactEmail === previousContactEmail) {
        return;
    }

    dispatch(
        emailRecipientsToChanged(
            uniqBy([...selectTo(getState()), contactEmail], (value) => {
                return value?.toLowerCase() || "";
            }).filter((address) => address !== previousContactEmail)
        )
    );
});

export function selectMatchingContactEmail(contact: Profile, recipients: Array<string>) {
    const emails = contact?.emails ? contact.emails.map((addr) => addr.email) : [];
    const match = recipients.find((email) => emails.includes(email));
    return match || contact?.email;
}

export const newEmailEventToRespondTo = createAsyncThunk<
    void,
    NullOr<EmailTaskAttributes["originatingEmailEvent"]>,
    { state: RootState }
>("emailUI/newEmailEventToRespondTo", (emailEventToRespondTo, { dispatch, getState }) => {
    const rootState = getState();

    // here we're trying to respond to an email event and instead of using the contact's primary email address
    // we'll first loop through all of the contact's emails and try to find a match in either of "sender email", "to", "cc" and "bcc" list
    // if no match is found, we'll fallback to either the primary email address (if one exists) or an empty string
    const contact = selectPageAwareContactObject(rootState);
    const contactEmail =
        (contact &&
            selectMatchingContactEmail(contact, [
                ...(emailEventToRespondTo?.senderEmail ? [emailEventToRespondTo.senderEmail.address] : []),
                ...(emailEventToRespondTo?.toEmails?.map((email) => email.address) || []),
                ...(emailEventToRespondTo?.ccEmails?.map((email) => email.address) || []),
                ...(emailEventToRespondTo?.bccEmails?.map((email) => email.address) || []),
            ])) ||
        "";

    dispatch(
        _newEmailEventAction({
            emailEventToRespondTo,
            agentEmail: selectAgentIntegrationEmail(rootState),
            contactEmail,
        })
    );
});

export const replyToEmailEvent = createAsyncThunk<
    void,
    EmailTaskAttributes["originatingEmailEvent"],
    { state: RootState }
>("emailUI/replyToEmail", (emailEventToRespondTo, { dispatch, getState }) => {
    const rootState = getState();

    const task = selectActiveTask(rootState);
    const contact = selectPageAwareContactObject(rootState);
    const persistenceKey = task?.sid ?? contact?.id;
    const agentEmail = selectAgentIntegrationEmail(rootState);
    const contactEmail =
        (contact &&
            selectMatchingContactEmail(contact, [
                ...(emailEventToRespondTo?.senderEmail ? [emailEventToRespondTo.senderEmail.address] : []),
                ...(emailEventToRespondTo?.toEmails?.map((email) => email.address) || []),
                ...(emailEventToRespondTo?.ccEmails?.map((email) => email.address) || []),
                ...(emailEventToRespondTo?.bccEmails?.map((email) => email.address) || []),
            ])) ||
        "";

    if (persistenceKey) {
        const replyEmailUIMeta = generateReplyEmailMeta({
            emailEventToRespondTo,
            agentEmail,
            contactEmail,
        });
        dispatch(
            persistDataForThread({
                key: persistenceKey,
                data: replyEmailUIMeta,
            })
        );
    }
    dispatch(_replyToEmailAction({ emailEventToRespondTo, agentEmail, contactEmail }));
});

export const persistedEmailMetaChanged = createAsyncThunk<
    void,
    {
        persistenceKey: string;
        uiMeta: Partial<NewEmailMeta>;
    },
    { state: RootState }
>("emailUI/persistedEmailMetaChanged", ({ persistenceKey, uiMeta }, { dispatch, getState }) => {
    const rootState = getState();

    const newEmailMeta = selectNewEmailMeta(rootState);
    const persistedEmailMeta = selectPersistedThread(rootState)(persistenceKey);
    const value = {
        ...newEmailMeta,
        ...persistedEmailMeta,
        ...uiMeta,
    };

    if (persistenceKey) {
        dispatch(
            persistDataForThread({
                key: persistenceKey,
                data: value,
            })
        );
    }
});
