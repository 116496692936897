import { sleep } from "Services/HelpersService";
import { getLogger } from "Services/LoggingService";

import retryDelay from "./RetryDelay";

const logger = getLogger("Server Events: MonitorAndRetryStrategy");

export type Service<T> = {
    init: () => Promise<T>;
    disconnect: () => void;
    isActive: () => boolean;
    close: () => void;
    reportHardFailure?: () => void;
};

// The heartbeatTimeoutMillis is a fixed value that is set to be slightly longer than the expected
// interval between heartbeats from the streaming server. If this amount of time elapses
// with no new data, the connection will be cycled.
export const heartbeatTimeoutMillis = 1.2 * 60 * 1000; // we should consider the connection dead if we don't receive a ping within a minute
export const eventSourceAutoRetryMillis = 5 * 1000; // this should be slightly greater than how frequently the EventSource retries automatically (~2-3 seconds);
export const hardFailTimeoutMillis = 5 * 60 * 1000; // stop trying after 5 minutes

class MonitorRetryStrategy<T> {
    isRetrying = false;
    errorCount = 0;
    shouldAttemptRetry = false; // first determine if retry attempt is needed
    hardFailHappened = false;
    heartbeatTimeoutId?: number;
    autoRetryTimeoutId?: number;
    hardFailTimeoutId?: number;

    constructor(protected service: Service<T>, protected retryService = retryDelay()) {
        this.monitorHeartbeats = this.monitorHeartbeats.bind(this);
        this.clearAllTimeouts = this.clearAllTimeouts.bind(this);
        this.handleHeartbeatFailure = this.handleHeartbeatFailure.bind(this);
        this.ensureRetry = this.ensureRetry.bind(this);
        this.kickoffRetries = this.kickoffRetries.bind(this);
    }

    /**
     * monitorHeartbeats sets the initial heartbeat timer to monitor for
     * pauses in the keep alive pings sent from the server.
     * If a lapse of more than a min is detected, it will kick off a retry and set the hard fail timer.
     * The hard fail timer will limit the duration of retries allowed
     */
    monitorHeartbeats(): void {
        this.clearAllTimeouts();
        this.isRetrying = false;
        this.heartbeatTimeoutId = Number(setTimeout(this.handleHeartbeatFailure, heartbeatTimeoutMillis));
    }

    clearAllTimeouts(): void {
        clearTimeout(this.heartbeatTimeoutId);
        clearTimeout(this.hardFailTimeoutId);
        clearTimeout(this.autoRetryTimeoutId);
        this.heartbeatTimeoutId = undefined;
        this.hardFailTimeoutId = undefined;
        this.autoRetryTimeoutId = undefined;
    }

    /**
     * isReceivingMessages will reset everything back to the initial state (no timers set)
     */
    resetToInitialState(): void {
        this.retryService.setGoodSince();
        this.clearAllTimeouts();
        this.errorCount = 0;
        this.isRetrying = false;
        this.shouldAttemptRetry = false;
        this.hardFailHappened = false;
        this.monitorHeartbeats(); // we can include messages in the heartbeat cadence
    }

    /**
     * ensureRetry will initially detect if the EventSource will automatically retry
     * We do not want to retry if the EventSource will retry because it will include the lastEventId header
     * (The lastEventId header enables SSE replay)
     * If we do not detect that the EventSource is automatically retrying
     * then we set `shouldAttemptRetry` = true and attempt to reconnect
     */
    async ensureRetry(): Promise<void> {
        if (this.shouldAttemptRetry) {
            await this.scheduleReconnect();
        } else {
            this.checkIfEventSourceWillRetry();
        }
    }

    /**
     * scheduleReconnect will attempt to reestablish an SSE connection using a delay
     * Note: That delay is calculated using exponential backoff and jitter
     * This method will only attempt to reestablish a connection if:
     *  1. There is no EventSource that is currently active
     *  2. A hard fail event has not occured (which will disconnect SSE)
     */
    private async scheduleReconnect(): Promise<void> {
        if (this.service.isActive()) {
            return;
        }

        const delay = this.retryService.getNextRetryDelay();
        await sleep(delay);

        clearTimeout(this.autoRetryTimeoutId); // clear retry timeout because we are retrying now
        this.autoRetryTimeoutId = undefined;

        if (this.hardFailHappened) {
            // we might hard fail during the "sleep" time and want to make sure that we do not retry
            return;
        }

        this.service.close();
        await this.service.init();
    }

    /**
     * The hard fail timer will place a hard limit on the duration
     * of time when we will make attempts to reestablish an SSE connection
     * If we hit the limit we will show an error message and disconnect.
     */
    private startHardFailTimer(): void {
        if (!this.hardFailTimeoutId) {
            this.hardFailTimeoutId = Number(
                setTimeout(() => {
                    logger.error("SSE Hard Failure after " + hardFailTimeoutMillis + "ms. Disconnecting.");
                    this.hardFailHappened = true;
                    this.isRetrying = false;
                    this.clearAllTimeouts();
                    this.service.disconnect();
                    this.service.reportHardFailure?.();
                }, hardFailTimeoutMillis)
            );
        }
    }

    private async handleHeartbeatFailure(): Promise<void> {
        logger.warn(
            "SSE Heartbeat Failure: Received no data in " +
                heartbeatTimeoutMillis +
                "ms, assuming connection is dead. Creating new connection."
        );

        this.clearAllTimeouts();
        this.startHardFailTimer();
        this.service.close();
        await this.scheduleReconnect();
    }

    /**
     * The EventSource will automatically retry in certain circumstances
     * If it retries, then it will retry on a regular cadence (every 2-3 seconds)
     * With each failed attempt to reestablish a connection, it will throw an error
     * If it succeeds, this timer will be cleared
     * If it continues to emit errors (e.g. network errors), we will count the errors and know that we do not need to retry
     * If there are fewer than 3 errors (likely 4xx or 5xx errors) then we must kick off retries
     */
    private checkIfEventSourceWillRetry(): void {
        this.startHardFailTimer();
        this.errorCount += 1;

        if (!this.autoRetryTimeoutId) {
            this.autoRetryTimeoutId = Number(setTimeout(this.kickoffRetries, eventSourceAutoRetryMillis));
        }
    }

    private async kickoffRetries() {
        if (this.errorCount < 3) {
            // meaning that the eventSource is NOT automatically retrying
            this.startHardFailTimer();
            this.shouldAttemptRetry = true;
            await this.scheduleReconnect();
        }
    }
}

export default MonitorRetryStrategy;
