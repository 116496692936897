import { clsx } from "clsx";

import styles from "./RVStatusBadge.module.scss";

export enum Status {
    OTHER = "",
    ERROR = "error",
    PENDING = "pending",
    ACTIVE = "active",
    INACTIVE = "inactive",
    IN_PROGRESS = "inProgress",
    DONE = "done",
    SUBSCRIBED = "subscribed",
    CONNECTED = "connected",
    UNSUBSCRIBED = "unsubscribed",
    DISCONNECTED = "disconnected",
    DYNAMIC = "dynamic",
    STATIC = "static",
    TEMPLATE = "template",
    TAG = "tag",
    VIEW_ONLY = "viewOnly",
}

export function RVStatusBadge({
    className = "",
    content = "",
    status = Status.PENDING,
    ...props
}: {
    content: string | JSX.Element;
    status: string;
    className?: string;
    [x: string]: any;
}): JSX.Element {
    return (
        <div className={styles.container} {...props}>
            <span className={clsx(styles.status, className, styles[status])} {...props}>
                {content}
            </span>
        </div>
    );
}
