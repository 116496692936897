import { useCallback } from "react";

import { useMutation } from "@apollo/client";
import { useSelector } from "react-redux";

import { getLogger } from "Services/LoggingService";
import { updateAgent } from "Services/marketing-api/agents/mutations";
import { selectAgentInformation, selectAgentActivity } from "Services/state/agent/AgentInformationSlice";
import { selectDefaultAvailableStatus } from "Services/state/brand";

export const logger = getLogger("Ensure availability");

export function useEnsureAvailability(): { ensureAgentIsAvailable: () => Promise<void> } {
    const agentInformation = useSelector(selectAgentInformation);
    const agentActivity = useSelector(selectAgentActivity);
    const availableActivity = useSelector(selectDefaultAvailableStatus);

    const [updateAgentMutation] = useMutation(updateAgent);

    const ensureAgentIsAvailable = useCallback(async () => {
        if (agentActivity.available) {
            return;
        }
        if (!availableActivity || !agentInformation.workerSid) {
            logger.error("Could not set agent to be available because necessary data was missing", {
                twilioWorkerSid: agentInformation.workerSid,
            });
            return;
        }
        try {
            await updateAgentMutation({
                variables: {
                    updateUserData: {
                        status: availableActivity.name,
                        twilioSid: agentInformation.workerSid,
                    },
                },
            });
            // update type to unknown and use normalizeError
        } catch (err) {
            logger.error("Error attempting to update agent status to active", {
                err,
                twilioWorkerSid: agentInformation.workerSid,
                status: availableActivity.name,
            });
        }
    }, [agentInformation, updateAgentMutation, availableActivity, agentActivity]);

    return { ensureAgentIsAvailable };
}
