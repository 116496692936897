import { lazy } from "react";

import { RVButton } from "Components/elements/RVButton/RVButton";
import { getLogger } from "Services/LoggingService";

const logger = getLogger("Lazy Loader");

/**
 * Tries to lazy load chunks with 5 retries. If all retries fail, the application will reload.
 */
export const lazyWithRetries = <T extends React.ComponentType<any>>(
    componentName: string,
    importer: () => Promise<{ default: T }>
): React.LazyExoticComponent<T> => {
    const retryImport = async () => {
        const MAX_RETRY_COUNT = 5;

        let modulePath: string | undefined;

        try {
            return await importer();
        } catch (error) {
            for (let attempt = 1; attempt <= MAX_RETRY_COUNT; attempt++) {
                // custom backoff to retry
                await new Promise((resolve) => setTimeout(resolve, Math.pow(2, 7 + attempt)));

                try {
                    // add cache bust as well
                    const bust = `bust=${Date.now()}-${attempt}`;
                    modulePath =
                        error instanceof Error
                            ? error.message
                                  .replace(/^Failed to fetch dynamically imported module:\s*/i, "")
                                  .split(/[\n'"]/)
                                  .shift()
                            : undefined;

                    if (!modulePath) {
                        throw error;
                    }

                    return await import(
                        /* @vite-ignore */
                        `${modulePath}${modulePath.includes("?") ? "&" : "?"}${bust}`
                    );
                } catch (retryError) {
                    logger.warn(`Chunk load retry failed`, { error: retryError, attempt, componentName });
                }
            }

            if (modulePath) {
                logger.error(`Failed to load chunk`, { attempts: MAX_RETRY_COUNT, modulePath });
            }

            throw error;
        }
    };

    return lazy(() =>
        retryImport().catch((error) => ({
            default: () => (
                <div style={{ height: "100%" }} className="flex-column justify-center align-middle">
                    <h1>Something went wrong while loading this page</h1>
                    <p>We are trying to reload the application...</p>
                    <RVButton type="primary" onClick={() => window.location.reload()}>
                        Reload Now
                    </RVButton>
                    <details style={{ marginTop: "1rem" }}>
                        <summary>Technical details</summary>
                        <pre style={{ whiteSpace: "pre-wrap", textAlign: "left" }}>{String(error)}</pre>
                    </details>
                </div>
            ),
        }))
    );
};
