import { createSelector } from "@reduxjs/toolkit";
import { RVTask } from "@regal-voice/shared-types";
import { memoize } from "lodash";

import { getLogger } from "Services/LoggingService";
import { selectADSubPage, AgentDesktopSubPages } from "Services/state/agent/desktopUI/AgentDesktopUISlice";
import { selectPageAwareContactObject } from "Services/state/contacts/Selectors/Selectors";

import { RootReducerShape } from "../Storage";
import { TasksState as RootTasksState } from "./IndexProvider";
import { TaskCreatedDate, TasksState } from "./TasksStateSlice";

import type { TaskToAutocompleteSettings } from "./TasksToAutocompleteSlice";

// THE ROOT STATE AND SLICES
const logger = getLogger("Task State");

export const SelectRootTasksState = (state: RootReducerShape): RootTasksState => state.tasks;
export const selectInternalTasksSlice = createSelector(SelectRootTasksState, (tasksState) => tasksState.tasks);
export const selectActiveTaskSlice = createSelector(SelectRootTasksState, (tasksState) => tasksState.activeTask);
export const selectTasksToAutocompleteSlice = createSelector(
    SelectRootTasksState,
    (tasksState) => tasksState.tasksToAutocomplete
);
const selectTasksToAutocomplete = createSelector(
    selectTasksToAutocompleteSlice,
    (tasksToAutocomplete) => tasksToAutocomplete.tasks
);
export const selectTasksSummarySlice = createSelector(
    SelectRootTasksState,
    (tasksState) => tasksState.taskSummaryModal
);
export const selectTaskSummaryIsSubmitted = createSelector(selectTasksSummarySlice, (summaryState) =>
    memoize((taskSid?: string) => {
        if (taskSid) {
            return Boolean(summaryState.submitted[taskSid]);
        }
        return false;
    })
);

// INTERIOR AND DERIVED STATE
export const selectTasks = createSelector(selectInternalTasksSlice, (tasksState) => {
    return tasksState.tasks;
});
export const selectWaitingForTaskState = createSelector(
    selectInternalTasksSlice,
    (tasksState) => tasksState.waitingForTaskState
);
export const selectTasksList = createSelector(selectTasks, (tasks) => Object.values(tasks ?? {}));
export const selectTasksLength = createSelector(selectTasksList, (list) => list.length);
export const selectHasTasks = createSelector(selectTasksLength, (tasksLength) => tasksLength > 0);
export const selectTask = createSelector(selectTasks, (tasks) =>
    memoize((reservationSid: string) => tasks[reservationSid])
);
export const selectActiveTaskSliceValue = createSelector(
    selectActiveTaskSlice,
    (activeTaskState) => activeTaskState.value
);

export const selectTasksForActiveContact = createSelector(
    selectTasksList,
    selectPageAwareContactObject,
    (taskList, contact) => {
        // TODO: for now we can use the contactPhone as a fallback, ideally we want to remove it and only rely on profileId
        if (!contact) {
            return [];
        }
        const { id, contactPhone } = contact;
        return taskList.filter(
            (task) => task.attributes.contactPhone === contactPhone || task.attributes.profileId === id
        );
    }
);

export const selectAcceptedTasksForActiveContact = createSelector(selectTasksForActiveContact, (activeList) => {
    return activeList.filter((task) => task.status === "accepted" || task.status === "wrapping");
});

export const selectMostRecentTaskForActiveContact = createSelector(
    selectAcceptedTasksForActiveContact,
    (acceptedList) => {
        if (acceptedList.length > 0) {
            return acceptedList.sort((a, b) => {
                // if both values are valid, compare them
                if (a.reservationUpdatedDate != undefined && b.reservationUpdatedDate != undefined) {
                    const dateA = new Date(a.reservationUpdatedDate);
                    const dateB = new Date(b.reservationUpdatedDate);
                    if (dateA > dateB) {
                        return -1;
                    } else if (dateA < dateB) {
                        return 1;
                    } else {
                        return 0;
                    }
                    // if one value is valid sent it to the back of the list
                } else {
                    if (undefined === a.reservationUpdatedDate) {
                        logger.warn("reservationUpdatedDate is undefined", { taskSid: a.taskSid });
                        return 1;
                    } else if (undefined === b.reservationUpdatedDate) {
                        logger.warn("reservationUpdatedDate is undefined", { taskSid: b.taskSid });
                        return -1;
                    }
                }
                return 0;
            })[0];
        }
        return undefined;
    }
);

export const selectActiveTask = createSelector(
    selectTasks,
    selectActiveTaskSlice,
    selectADSubPage,
    selectMostRecentTaskForActiveContact,
    (tasks, activeTaskSlice, subPage, mostRecent) => {
        if (subPage === AgentDesktopSubPages.TASKS_PAGE) {
            const activeTaskValue = activeTaskSlice.value;
            return activeTaskValue ? tasks[activeTaskValue] : undefined;
        } else if (subPage === AgentDesktopSubPages.CONTACT_PAGE) {
            return mostRecent;
        }
        return undefined;
    }
);

export const selectReservationSidStatusKey = createSelector(selectTasks, (tasks) => {
    return Object.values(tasks)
        .map(({ sid: reservationSid, status }) => `${reservationSid}:${status}`)
        .sort()
        .join(",");
});

export const selectOldTasks = createSelector(selectInternalTasksSlice, (tasksState) => tasksState.taskCreatedDateBySid);

function getCreatedDate(task: RVTask, originalTaskCreatedDate?: string): number {
    // bug bash clean up, I think our typing is off somewhere.
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return new Date(originalTaskCreatedDate ?? task.reservationCreatedDate!).getTime();
}

export function sortTasks(tasks: TasksState, taskCreatedDateBySid: TaskCreatedDate): RVTask[] {
    return Object.values(tasks).sort((left, right) => {
        const createdDateLeft = getCreatedDate(left, taskCreatedDateBySid[left.attributes.original_task_sid]);
        const createdDateRight = getCreatedDate(right, taskCreatedDateBySid[right.attributes.original_task_sid]);
        return createdDateRight - createdDateLeft;
    });
}

export const selectSortedTasks = createSelector(selectTasks, selectOldTasks, (tasks, taskCreatedDateBySid) => {
    const tasksValues = Object.values(tasks);
    const mapOriginalTaskSidToTask = Object.fromEntries(
        tasksValues
            .filter((task) => !!task.attributes.original_task_sid)
            .map((task) => [task.attributes.original_task_sid, task])
    );

    // remove duplicated reservations for the same task
    const uniqueTasks = Object.fromEntries(
        tasksValues
            .filter(
                (task) =>
                    mapOriginalTaskSidToTask[task.taskSid] == null ||
                    (mapOriginalTaskSidToTask[task.taskSid] != null && task.attributes.original_task_sid != null)
            )
            .map((task) => [task.sid, task])
    );

    // show only tasks that are not completed
    const notCompletedTasks = Object.fromEntries(
        Object.values(uniqueTasks)
            .filter((task) => task.status != "completed" && task.status != "canceled")
            .map((task) => [task.sid, task])
    );

    return sortTasks(notCompletedTasks, taskCreatedDateBySid);
});

export const autocompleteSettingsForTaskSelector = createSelector(selectTasksToAutocomplete, (tasks) =>
    memoize(
        (...taskSids: string[]): TaskToAutocompleteSettings | undefined => {
            if (!taskSids.length) {
                return undefined;
            }

            const sid = taskSids.find((sid) => tasks[sid]);
            return sid ? tasks[sid] : undefined;
        },
        (...taskSids) => taskSids.join(",") // memoize by joining all passed task sids
    )
);

export const autocompletePendingTasksSelector = createSelector(selectTasksToAutocomplete, (tasks): string[] =>
    Object.entries(tasks).reduce<string[]>((acc, [sid, settings]) => {
        if (settings.pendingRemoval) {
            acc.push(sid);
        }
        return acc;
    }, [])
);
