import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ManagerCallAction } from "@regal-voice/shared-types";
import { isNil } from "lodash";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { RootReducerShape, ThunkAction } from "Services/state/Storage";
import { selectTasks } from "Services/state/tasks/Selectors";
import { CallStatusEvent } from "Services/Task.service";

export interface ParticipantInfo {
    callSid: string | undefined;
    conferenceFriendlyId: string | undefined;
    conferenceSid: string | undefined;
    phoneNumber: string | undefined;
    startTimestamp: number | undefined;
    status: CallStatusEvent | undefined;
    name?: string;
    type: "contact" | "external" | "internal" | "external-phonebook";
    onHold?: boolean;
    isMuted?: boolean;
    from: string;
    sequenceNo: string;
    to: string;
    taskSid: string;
    queueSid?: string;
    queueName?: string;
    managerAction?: ManagerCallAction;
}

export interface ConferenceEntry {
    deviceCallSid: string | undefined;
    connectionParams: Record<string, string | undefined>;
    conferenceFriendlyId: string | undefined;
    conferenceSid: string | undefined;
    isRecording: boolean | undefined;
    connected: boolean;
    participants: {
        [sid: string]: ParticipantInfo;
    };
    duration: number;
}

export type ConferenceState = {
    value: ConferenceEntry;
};

const initialState: ConferenceState = {
    value: {
        deviceCallSid: undefined,
        conferenceFriendlyId: undefined,
        conferenceSid: undefined,
        isRecording: undefined,
        connected: false,
        participants: {},
        duration: 0,
        connectionParams: { taskSid: undefined },
    },
};

const ConferenceStateSlice = createSlice({
    name: "activeConference",
    initialState,
    reducers: {
        // after the rolloutUseConferenceStateSse and rolloutUseConferenceStateSseActions are removed
        // these can probably be cleaned up some. I dont think we need ALL OF these values.
        // I think it is JUST the ConnectionParams taskSid and Params that we need to get the
        // active task for calls where we dont have a conference snapshot yet
        setConference(state, action: PayloadAction<Partial<ConferenceEntry>>): ConferenceState {
            return {
                ...state,
                value: {
                    ...state.value,
                    deviceCallSid: action.payload.deviceCallSid ?? state.value.deviceCallSid,
                    conferenceFriendlyId: action.payload.conferenceFriendlyId,
                    conferenceSid: action.payload.conferenceSid,
                    participants: action.payload.participants,
                    connected: action.payload.connected,
                    isRecording: isNil(state.value.isRecording) ? action.payload.isRecording : state.value.isRecording,
                } as ConferenceEntry,
            };
        },
        setDeviceParams(state, action: PayloadAction<{ taskSid: string | undefined }>) {
            return {
                ...state,
                value: {
                    ...state.value,
                    connectionParams: action.payload,
                },
            };
        },
        setConferenceTaskSid(state, action: PayloadAction<{ taskSid?: string }>) {
            const updatedConnectionParams = { ...state.value.connectionParams, ...action.payload };
            state.value.connectionParams = updatedConnectionParams;
        },
        clearConference: () => {
            return initialState;
        },
        // do the clearConference + setConferenceTaskSid so that we dont get a ui stutter on incoming calls
        resetConferenceForAcceptance(state, action: PayloadAction<{ taskSid?: string }>) {
            const updatedConnectionParams = { ...action.payload };
            return {
                ...initialState,
                value: {
                    ...initialState.value,
                    connectionParams: updatedConnectionParams,
                },
            };
        },

        ////////////////////////////////////////////////

        /*
         * These can all be remove once we remove the rolloutUseConferenceStateSseActions flag
         */
        setConferenceDuration(state, action: PayloadAction<{ duration: number }>) {
            state.value.duration = action.payload.duration;
        },
        setConferenceRecording(state, action: PayloadAction<Partial<ConferenceEntry>>): ConferenceState {
            return {
                ...state,
                value: {
                    ...state.value,
                    isRecording: action.payload.isRecording,
                } as ConferenceEntry,
            };
        },
        setConferenceConnectionSid(state, action: PayloadAction<{ connectionCallSid?: string }>) {
            state.value.deviceCallSid = action.payload.connectionCallSid;
        },
    },
});

export const {
    actions: {
        clearConference,
        setConference,
        setConferenceTaskSid,
        resetConferenceForAcceptance,
        setDeviceParams,
        setConferenceDuration,
        setConferenceRecording,
        setConferenceConnectionSid,
    },
    name,
    reducer,
} = ConferenceStateSlice;

export function selectActiveCallSid({ conference }: RootReducerShape) {
    return conference[ConferenceStateSlice.name].value.deviceCallSid;
}

export function setActiveConferenceTask({
    id,
}: {
    id?: string;
}): ThunkAction<void, RootReducerShape, unknown, AnyAction> {
    return function thunk(
        dispatch: ThunkDispatch<RootReducerShape, unknown, AnyAction>,
        getState: () => RootReducerShape
    ): void {
        let taskSid;
        if (id) {
            const task = selectTasks(getState())[id];
            taskSid = task?.taskSid;
        }
        dispatch(setConferenceTaskSid({ taskSid }));
    };
}

// resetConferenceForAcceptance combines the setConferenceTaskSid and the clearConference actions
// so that we dont get a a ui stutter on incoming calls
export function resetActiveConferenceTask({
    id,
}: {
    id: string;
}): ThunkAction<void, RootReducerShape, unknown, AnyAction> {
    return function thunk(
        dispatch: ThunkDispatch<RootReducerShape, unknown, AnyAction>,
        getState: () => RootReducerShape
    ): void {
        const task = selectTasks(getState())[id];
        const taskSid = task?.taskSid;
        dispatch(resetConferenceForAcceptance({ taskSid }));
    };
}
