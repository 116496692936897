import { ReactNode, PropsWithChildren, ReactElement } from "react";

import { InfoCircleOutlined } from "@ant-design/icons";
import { Form, FormItemProps, Tooltip, Typography } from "antd";
import { clsx } from "clsx";

import { FloatingLabelWrapper } from "./FloatingLabel";

import styles from "./EnhancedFormItem.module.scss";

export type EnhancedSelectProps = FormItemProps & {
    showFormLabel?: boolean;
    formItemHeader?: ReactNode;
    formItemHeaderTooltip?: ReactNode;
    disabled?: boolean;
    containerClassName?: string;
    fixedFloatingLabel?: boolean;
    clearable?: boolean;
    placeholder?: string;
    infoItem?: ReactNode | ReactElement;
    infoText?: string | ReactNode;
    subInfoText?: string;
    dataTestId?: string;
    disablePlaceholder?: boolean;
    suffix?: string;
    suffixClassName?: string;
};

const WithSuffix = ({ children, suffix, className }: { children: ReactNode; suffix?: string; className?: string }) => {
    if (!suffix) {
        return <>{children}</>;
    }

    return (
        <div className={clsx(styles.withSuffix, className)}>
            {children}
            {suffix && <Typography.Text>{suffix}</Typography.Text>}
        </div>
    );
};

export function EnhancedFormItem({
    name,
    showFormLabel,
    formItemHeader,
    formItemHeaderTooltip,
    label,
    fixedFloatingLabel,
    className,
    containerClassName,
    suffixClassName,
    clearable,
    children,
    placeholder,
    infoText,
    infoItem,
    subInfoText,
    dataTestId,
    disablePlaceholder,
    suffix,
    ...rest
}: PropsWithChildren<EnhancedSelectProps>): JSX.Element {
    const showRequiredLabel = rest.rules?.some(
        // @ts-expect-error type is wrong this is a BaseRule from antd
        (rule) => rule.required === true || typeof rule.validator === "function"
    );

    return (
        <div
            data-testid={dataTestId ?? `enhanced-input-${name}`}
            className={clsx("floating-design", containerClassName, { "floating-design-disabled": rest.disabled })}
        >
            {showFormLabel && (
                <div className="ant-form-item-label">
                    <label>
                        {formItemHeader ?? label} {showRequiredLabel && <sup>*</sup>}
                        {formItemHeaderTooltip ? (
                            <Tooltip title={formItemHeaderTooltip} placement="topLeft">
                                <InfoCircleOutlined className={styles.tooltipIcon} />
                            </Tooltip>
                        ) : (
                            ""
                        )}
                    </label>
                    {infoItem}
                </div>
            )}
            {infoText && <Typography.Text className="info-text">{infoText}</Typography.Text>}
            <WithSuffix suffix={suffix} className={suffixClassName}>
                <FloatingLabelWrapper name={name} fixedFloatingLabel={fixedFloatingLabel} clearable={clearable}>
                    <Form.Item
                        data-testid={`${name}-testid`}
                        label={disablePlaceholder ? "" : placeholder || label}
                        name={name}
                        className={className}
                        {...rest}
                    >
                        {children}
                    </Form.Item>
                </FloatingLabelWrapper>
            </WithSuffix>
            {subInfoText && <div className={styles.formItemSubText}>{subInfoText}</div>}
        </div>
    );
}
